import React from 'react';

import EmailIcon from '../../../../assets/images/social/email.inline.svg';
import GithubIcon from '../../../../assets/images/social/github.inline.svg';
import LinkedinIcon from '../../../../assets/images/social/linkedin.inline.svg';

import { LinksList, SocialIconAnchor, SocialIconItem } from './styles';

const socialLinks = [
  {
    id: 1,
    name: 'Email',
    Icon: EmailIcon,
    href: 'mailto:garcia.idaslon@gmail.com',
  },
  {
    id: 2,
    name: 'Linkedin',
    Icon: LinkedinIcon,
    href: 'https://www.linkedin.com/in/idaslon',
  },
  {
    id: 3,
    name: 'Github',
    Icon: GithubIcon,
    href: 'https://github.com/Idaslon',
  },
]

const SocialLinks: React.FC = () => {
  return (
    <LinksList>
      {socialLinks.map((socialLink) => (
        <SocialIconItem key={socialLink.id}>
          <SocialIconAnchor href={socialLink.href} target='_blank' aria-label={socialLink.name}>
            <socialLink.Icon />
          </SocialIconAnchor>
        </SocialIconItem>
      ))}
    </LinksList>
  )
}

export default SocialLinks;
