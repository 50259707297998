import { styled } from "../../stitches.config";

export const TypographyH1 = styled('h1', {
  fontWeight: 700,
  fontStyle: 'normal',
  textTransform: 'uppercase',

  variants: {
    size: {
      small: {
        fontSize: '4.2rem',

        '@medium': {
          fontSize: '6.2rem'
        },
      },
      medium: {
        fontSize: '5.2rem',

        '@medium': {
          fontSize: '6.2rem'
        },
      },
      large: {}
    }
  }
});

export const TypographyH2 = styled('h2', {
  fontWeight: 400,
  fontStyle: 'normal',

  variants: {
    size: {
      small: {},
      medium: {
        fontSize: '4.0rem',

        '@medium': {
          fontSize: '4.6rem'
        },
      },
      large: {},
    }
  }
});

export const TypographyH3 = styled('h3', {
  fontWeight: 300,
  fontStyle: 'normal',

  variants: {
    size: {
      small: {},
      medium: {
        fontSize: '3.2rem',

        '@medium': {
          fontSize: '4.2rem'
        },
      },
      large: {},
    }
  }
});

export const TypographySubtitle1 = styled('h3', {
  fontWeight: 400,
  fontStyle: 'normal',

  variants: {
    size: {
      small: {},
      medium: {
        fontSize: '3.2rem',
      },
      large: {},
    }
  }
});

export const TypographySubtitle2 = styled('h4', {
  fontWeight: 500,
  fontStyle: 'normal',

  variants: {
    size: {
      small: {},
      medium: {
        fontSize: '2.0rem',
      },
      large: {},
    }
  }
});

export const TypographyBody1 = styled('p', {
  fontWeight: 500,
  fontStyle: 'normal',

  variants: {
    size: {
      small: {},
      medium: {
        fontSize: '1.4rem',

        '@medium': {
          fontSize: '1.6rem'
        },
      },
      large: {
        fontSize: '1.6rem',

        '@medium': {
          fontSize: '1.8rem'
        },
      }
    }
  }
});

export const TypographyBody2 = styled('span', {
  fontWeight: 400,
  fontStyle: 'normal',

  variants: {
    size: {
      small: {},
      medium: {
        fontSize: '1.4rem',

        '@medium': {
          fontSize: '1.6rem'
        },
      },
      large: {
        fontSize: '1.6rem',

        '@medium': {
          fontSize: '1.8rem'
        },
      }
    }
  }
});
