import { createStitches } from "@stitches/react";

export const { styled, css, getCssText, keyframes } = createStitches({
  theme: {
    colors: {
      commonWhite: 'hsl(0, 0%, 100%)',
      commonBlack: 'hsl(220, 33%, 9%)',

      gray200: 'hsl(0, 0%, 94%)',
      gray300: 'hsl(0, 0%, 88%)',
      grey350: 'hsl(0, 0%, 87%)',
      grey500: 'hsl(0, 0%, 58%)',
      grey600: 'hsl(0, 0%, 48%)',
      grey700: 'hsl(0, 0%, 36%)',
      grey800: 'hsl(0, 0%, 30%)',

      iconBackground: 'hsl(213, 18%, 22%)',
      iconBackgroundLight: 'hsl(215, 19%, 26%)',

      skeletonStartColor: 'hsl(210, 38%, 95%)',
      skeletonEndColor: 'hsl(214, 20%, 69%)',

      shadow1: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0.01%, rgba(1, 1, 1, 0.0945957) 18.23%, rgba(0, 0, 0, 0.163006) 32.29%, rgba(0, 0, 0, 0.4) 96.87%)'
    },
    shadows: {
      elevation1: '0px 2px 5px hsla(0, 0%, 0%, 0.04)',
      elevation2: '0px 4px 44px rgba(0, 0, 0, 0.04)',
    },
    zIndices: {
      drawerIndex: 1,
      floatIndex: 2,
    },
    space: {
      1: '8px',
      2: '16px',
      3: '24px',
      4: '32px',
      5: '40px',
      6: '48px',

      section: '150px',
    },
  },
  media: {
    extraSmall: '(min-width: 0px)',
    small: '(min-width: 360px)',
    medium: '(min-width: 700px)',
    large: '(min-width: 1024px)',
    extraLarge: '(min-width: 1200px)',
    wide: '(min-width: 1440px)',
  },
});

export function spacing(value: number) {
  return value * 8
}

export const fadeIn = keyframes({
  from: {
    opacity: 0
  },

  to: {
    opacity: 1
  }
})

export const fadeOut = keyframes({
  from: {
    opacity: 1
  },

  to: {
    opacity: 0
  }
})
