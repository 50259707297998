import React from 'react';
import Footer from '../components/Footer';

import SEO from '../components/SEO';

import { Main } from './styles';

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <SEO />
      <Main>{children}</Main>
      <Footer />
    </>
  )
}

export default Layout;
