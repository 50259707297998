import { spacing, styled } from "../../stitches.config";
import { TypographyBody2 } from "../Typography/styles";

export const FooterContainer = styled('footer', {
  marginTop: '$section',

  color: '$commonWhite',
  background: '$commonBlack',

  paddingTop: spacing(6),
  paddingBottom: spacing(6),
  paddingInline: spacing(4.5),

  '@large': {
    paddingTop: spacing(7),
    paddingBottom: spacing(8),
    paddingInline: spacing(9),
  },

  '@extraLarge': {
    paddingBottom: spacing(9),
    paddingInline: '124px',
  },
})

export const FooterRow = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  rowGap: spacing(4.5),
  width: '100%',

  '@medium': {
    columnGap: '$4',

    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  '@large': {
    maxWidth: '900px',
  }
})

export const FooterColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  width: '100%',
  maxWidth: '240px',

  rowGap: spacing(1.25),
})

export const FooterTypography = styled(TypographyBody2, {
  color: '$gray300',
  fontSize: '1.6rem'
})
