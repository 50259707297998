import React from 'react';

import { ExternalLink } from '../Link';
import Typography from '../Typography';

import ProjectsLinks from './components/ProjectsLinks';
import SocialLinks from './components/SocialLinks';

import {
  FooterColumn,
  FooterContainer,
  FooterRow,
  FooterTypography
} from './styles';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterRow>
        <FooterColumn>
          <Typography variant='subtitle2' as='p'>Contact Information</Typography>
          <FooterTypography>
            I don't use social media a lot, but feel free to reach me out anytime.
          </FooterTypography>

          <SocialLinks />
        </FooterColumn>

        <FooterColumn>
          <Typography variant='subtitle2' as='p'>Availability</Typography>
          <FooterTypography>
            I am currently a Frontend Engineer at
            {' '}

            <ExternalLink href='https://popstand.com' target='_blank'>Popstand</ExternalLink>

            {' '}
            but always open for new opportunities.
          </FooterTypography>
        </FooterColumn>

        <FooterColumn>
          {/* TODO: Projects */}
          {/* <Typography variant='subtitle2' as='p'>Featured Projects</Typography>
          <ProjectsLinks /> */}
        </FooterColumn>
      </FooterRow>
    </FooterContainer>
  )
}

export default Footer;
