import { spacing, styled } from "../../../../stitches.config";

export const LinksList = styled('ul', {
  display: 'flex',
  alignItems: 'center',

  marginTop: '$1',
  columnGap: spacing(2.5),
})

export const SocialIconItem = styled('li', {
})

export const SocialIconAnchor = styled('a', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  padding: spacing(1.25),

  borderRadius: '50%',
  background: '$iconBackground',

  cursor: 'pointer',

  '&:hover': {
    background: '$iconBackgroundLight',
  },

  svg: {
    width: '24px',
    height: '24px',
  },

  '@large': {
    padding: spacing(1.5),

    svg: {
      width: '26px',
      height: '26px',
    },
  }
})
