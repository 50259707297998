import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

interface Props {
  title?: string
  description?: string
}

const SEO: React.FC<Props> = ({ title, description }) => {
  const { site } = useStaticQuery(query)
  const { pathname } = useLocation();

  const { lang, image, domain, defaultTitle, defaultDescription } = site.siteMetadata

  const seo = {
    image,
    title: title || defaultTitle,
    description: description || defaultDescription,
    domain,
    url: `https://${domain}${pathname}`,
  }

  const htmlAttributes = {
    lang
  }

  return (
    <Helmet title={seo.title} htmlAttributes={htmlAttributes}>
      <meta name="description" content={seo.description} />
      <meta name="theme-color" content='#FFFFFF' />
      <meta name="referrer" content='origin' />
      <meta name="msapplication-TitleColor" content='#0F141E' />
      {/* <meta name="google" content='notranslate' /> */}

      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content='Website' />
      <meta property="og:title" content={seo.title} />
      <meta property="og:site_name" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:locale" content='en' />
      <meta property="og:image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={seo.domain} />
      <meta property="twitter:url" content={seo.url} />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        lang
        image
        domain
        defaultTitle: title
        defaultDescription: description
      }
    }
  }
`

export default SEO;
