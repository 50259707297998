import { Link as RootLink } from "gatsby";
import { styled } from "../../stitches.config";

export const Link = styled(RootLink, {
  color: 'inherit',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  }
})

export const ExternalLink = styled('a', {
  color: 'inherit',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  }
})
